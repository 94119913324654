!(function ($) {

    "use strict";

    var NS = function () {

        var _that = this;

        /*this.hover_reg_item = function () {
         var reg_item = $("#block-qarea-account-menu > ul > li > a");
         reg_item.each(function (i, el) {
         var word = $(this).text();
         $(this).hover(function () {
         $(this).attr("data-after", word);
         });
         });
         };*/

        if ($('.messages').length){
            $('.messages').append('<div class="close-message">X</div>');
        }
        $('.close-message').on("click", function () {
            $('.messages').hide(600);
            return false;
        });

        this.user_tickets_tab_wrap_moving = function () {
            var $user_tickets_tab_wrap = $(".user_tickets_tab_wrap");
            $user_tickets_tab_wrap.closest("#block-qarea-content").addClass("user_tickets_tab_wrap_parent");
            $user_tickets_tab_wrap.find(".left h2").addClass("opened");
            $user_tickets_tab_wrap.find(".left h2").on("click", function () {
                $(this).toggleClass("opened");
                $(".ticket_form").slideToggle('slow');
            });


            if($user_tickets_tab_wrap.length){

                var reload_time = 2;   // in minutes

                var timerId = setInterval(function() {
                    //console.log("tic");
                    location.reload();
                }, reload_time * 60000);
                if($user_tickets_tab_wrap.find("#edit-message").val() && $user_tickets_tab_wrap.find("#edit-message").val().length){
                    setTimeout(function() {
                        clearInterval(timerId);
                        //console.log('stop');
                    }, 0);
                }

                $user_tickets_tab_wrap.find("#edit-message").on("change, keyup", function () {
                    if($user_tickets_tab_wrap.find("#edit-message").val().length){
                        //console.log($user_tickets_tab_wrap.find("#edit-message").val().length);
                        setTimeout(function() {
                            clearInterval(timerId);
                            //console.log('stop');
                        }, 0);
                    }else{
                        //console.log("carry on");
                        timerId = setInterval(function() {
                            //console.log("tic");
                            location.reload();
                        }, reload_time * 60000);
                    }
                });


                /*if($user_tickets_tab_wrap.find("#edit-message").val() !== 0 || $user_tickets_tab_wrap.find("#edit-message").val() !== false){
                    setTimeout(function() {
                        clearInterval(timerId);
                        console.log( 'стоп' );
                    }, 0);
                }

                $user_tickets_tab_wrap.find("#edit-message").on("change, keyup", function () {
                    if($user_tickets_tab_wrap.find("#edit-message").val() !== 0 || $user_tickets_tab_wrap.find("#edit-message").val() !== false){
                        setTimeout(function() {
                            clearInterval(timerId);
                            console.log('стоп');
                        }, 0);
                    }else{
                        var timerId = setInterval(function() {
                            console.log("тик");
                        }, 5000);
                    }
                });*/

            }



            /*var timer = false;
            var reloadTime = 5000;


            $user_tickets_tab_wrap.find("#edit-message").on("change, keyup", function () {
                if($(this).val().length >= 0){
                    reloadTime = 36000000000;
                    reloadTimer(reloadTime);
                }else{
                    reloadTime = 5000;
                    reloadTimer(reloadTime);
                }
            });


            function reloadTimer(reloadTime) {
                if (!!timer){
                    clearTimeout(timer);
                }
                timer = setTimeout(function(){
                    location.reload();
                }, reloadTime);
            }*/


            /*var null_func = function () {
                return false;
            };

            var reload_page = function () {
                var date_time = new Date();
                console.log(date_time);
            };setInterval(reload_page, 1000);

            clearInterval(reload_page);

            var time_enter = function () {

            };*/

            /*$user_tickets_tab_wrap.closest("html").find('head').append("<noscript id='no_scr_for_chat'></noscript>");

            setTimeout(function () {
                $user_tickets_tab_wrap.closest("html").find('head').append('<meta class="refresh_for_chat" http-equiv="Refresh" content="15" />');
            }, 5);

            $user_tickets_tab_wrap.find("#edit-message").on("change, keyup", function () {
                if($(this).val().length >= 0){
                    $user_tickets_tab_wrap.closest("html").find('head').find('.refresh_for_chat').attr("content","3600");
                    console.log($(this).val());
                }else{
                    $user_tickets_tab_wrap.closest("html").find('head').find('.refresh_for_chat').attr("content","15");
                }
            });*/
        };

        this.user_register_form = function () {
            $(".user-register-form").each(function () {

                if($(this).find("#edit-account .js-form-type-email label.form-required").length){
                    $(this).find("#edit-account .js-form-type-email").append("<span class='reg_required'>(*)</span>");
                }
                if($(this).find("#edit-account .js-form-type-textfield label.form-required").length){
                    $(this).find("#edit-account .js-form-type-textfield").append("<span class='reg_required'>(*)</span>");
                }
                if($(this).find(".field--type-telephone .js-form-type-tel label.form-required").length){
                    $(this).find(".field--type-telephone .js-form-type-tel").append("<span class='reg_required'>(*)</span>");
                }

                /*var $email_input = $(this).find("#edit-mail");
                if($email_input.hasClass("error")){
                    $email_input.css({
                        "border-bottom": "1px solid red;"
                    });
                }else{
                    $email_input.css({
                        "border-bottom": "1px solid transparent;"
                    });
                }*/
            });
        };

        this.scroll_bar = function () {
            var $ticket_name = $(".ticket_name");
            var $tickets_div = $(".tickets > div");
            $(".user_tickets_tab_wrap .right").append($ticket_name);
            $(".user_tickets_tab_wrap .right .ticket_messages").addClass("scrollbar-inner");
            setTimeout(function () {
                $('.ticket_messages').scrollbar();
            },0);
            $(".user_tickets_tab_wrap .right .views-row .message.answer").each(function () {
                $(this).parent().addClass("message_answer_parent");
            });
            $tickets_div.each(function () {
                $(this).addClass("scrollbar-inner");
                $(this).scrollbar();
            });
        };

        this.create_tip_wrapper = function () {
            $(".create-tip-wrapper").each(function (i, el) {
                $(this).closest("header").addClass("rfilter_wrap");
            });
        };

        this.hover_sub_menu = function () {
            var $top_region_items = $("#block-topsitemenu > ul > li");
            $top_region_items.each(function (i, el) {
                var _this = $(this);
                if(_this.find("ul").length) {
                    _this.addClass("has_child");
                    //_this.find("ul").attr('data-left', 'left_item_' + i + 1);
                    _this.find("ul").css({
                        'left': _this.position().left + _this.width() / 1.5 + 'px',
                        'margin-left':  - _this.find("ul").width() / 2 + 'px',
                        'top': _this.outerHeight()
                    });
                }
            });
        };

        this.custom_radio = function () {
            $("body.path-node #block-qarea-content form.node-deposit-request-form .form-wrapper .fieldgroup .fieldset-wrapper .webform-options-display-one-column .form-item label.option").append("<span class='custom_helper_radio'></span>");
            $("body.path-node #block-qarea-content form.node-withdraw-request-form .form-wrapper .fieldgroup .fieldset-wrapper .webform-options-display-one-column .form-item label.option").append("<span class='custom_helper_radio'></span>");
        };

        this.slide_menu = function () {
            var $hcm = jQuery('.js_mobile_button'),
                $master = jQuery('.layout-container'),
                $slideMenu = jQuery('#slide_menu'),
                $nano = jQuery('.nano');
            if ($nano.length) {
                $hcm.on('click', function () {
                    $hcm.toggleClass("active");
                    $master.toggleClass("slided");
                    $slideMenu.toggleClass("slided");
                    return false;
                });
                jQuery('.slideMenuClose').on('click', function () {
                    $hcm.removeClass('active');
                    $master.removeClass('slided');
                    $slideMenu.removeClass('slided');
                });

                $nano.nanoScroller({
                    preventPageScrolling: true,
                    alwaysVisible: true,
                    iOSNativeScrolling: true
                });

                var $block_topsitemenu__2 = $("#block-topsitemenu--2");

                $block_topsitemenu__2.find(" > ul > li").each(function (i, el) {
                    if($(this).find("ul").length){
                        $(this).addClass("has_child_mobile");
                    }
                });

                var $has_child_mobile = $(".has_child_mobile");

                $has_child_mobile.each(function (i, el) {
                    var _that = $(this);
                    _that.on("click", function (e) {
                        if(e.target.nodeName == 'LI'){
                            _that.toggleClass("open");
                            _that.find(" > ul").slideToggle(200);
                        }
                    });
                });



            }
        };

        this.main_table_wrapper = function () {
            var $main_table = $(".main_table"),
                $matches_stats = $(".matches-stats");

            $main_table.each(function () {
                var $this = $(this);
                $this.wrap('<div class="main_table_wrapper"></div>');
            });
            $matches_stats.each(function () {
                var $this = $(this);
                $this.wrap('<div class="main_table_wrapper"></div>');
            });
        };

        this.slider_head_matches = function () {
            var $slider_head_matches = $(".slider_head_matches");
            if($slider_head_matches.length){
                $slider_head_matches.each(function (i, el) {
                    var $team_ratio_clone = $(this).find(".team_ratio").clone();
                    $team_ratio_clone.appendTo($(this).parent()).addClass("team_ratio_clone");
                });
            }
        };

        this.main_slide_show_slider = function () {
            var $main_slideshow = $(".main-slideshow");
            if($main_slideshow.length){
                // .main-slideshow .item-list ul li.slide a>img
                $main_slideshow.each(function (i, el) {
                    var _that = $(this);

                    function resize_height_img() {
                        if($(window).height() <= 820){
                            _that.find(".item-list ul li.slide a > img").css({
                                "max-height": 460 + 'px'
                            });
                        }else{
                            _that.find(".item-list ul li.slide a > img").removeAttr("style");
                        }
                    }resize_height_img();
                    $(window).on("resize", function () {
                        resize_height_img();
                    });

                    if($(window).height() <= 740){
                        _that.find(".item-list ul li.slide a > img").css({
                            "max-height": 460 + 'px'
                        });
                    }else{
                        _that.find(".item-list ul li.slide a > img").removeAttr("style");
                    }

                    if($("body").hasClass("lang_fa")){
                        _that.find("ul").slick({
                            adaptiveHeight: true,
                            arrows: false,
                            dots: true,
                            slidesToShow: 1,
                            rtl: true,
                            autoplay:true,
                            autoplaySpeed: 10000
                        });
                    }else{
                        _that.find("ul").slick({
                            adaptiveHeight: true,
                            arrows: false,
                            slidesToShow: 1,
                            dots: true,
                            autoplay: false,
                            autoplaySpeed: 10000
                        });
                    }
                });
            }
        };

        this.login_links = function () {
            $("#header #header-wrapper #block-qarea-account-menu--2 > ul").each(function () {
                $(this).find("li").each(function () {
                    $(this).find("a").each(function () {
                        if($(this).attr("data-drupal-link-system-path") == 'user'){
                            $(this).parent().addClass("user_account_item");
                            $(this).addClass("user_account_ref").wrap( "<div class='account_links'></div>" );
                            if(drupalSettings.bticket.user_unread_messages >= 1) {
                                $('.account_links').append("<a class='messages_count' href='/user/" + drupalSettings.bticket.uid + "/tickets'><span>" + drupalSettings.bticket.user_unread_messages + "</span></a>");
                            }
                            //$(this).attr("data-messages", drupalSettings.bticket.user_unread_messages);
                        }
                    });
                });
            });
        };

        this.language_switcher = function () {
            var $language_switcher_language_url = $(".language-switcher-language-url");
            $language_switcher_language_url.each(function (i, el) {
                var _that = $(this);
                $(this).find(".links").each(function () {
                    var $this_links = $(this);
                    $this_links.find(" > li > a.language-link.is-active").on("click", function () {
                        //console.log($(this));
                        return false;
                    });
                    $this_links.clone().removeClass("links").addClass("clone_links").appendTo($this_links.find(" > li"));
                    $this_links.find(" > li").each(function () {
                        var hreflang_attr = $(this).attr("hreflang");
                    });
                });
            });
        };

        this.bookmaker_slider = function () {
            var $bookmaker_slider = $(".bookmaker_slider");
            if($bookmaker_slider.length){
                $bookmaker_slider.each(function () {
                    if($("body").hasClass("lang_fa")){
                        $(this).find("ul").slick({
                            dots: false,
                            arrows: true,
                            slidesToShow: 4,
                            slidesToScroll: 4,
                            rtl: true,
                            responsive: [
                                {
                                    breakpoint: 768,
                                    settings: {
                                        slidesToShow: 3,
                                        slidesToScroll: 3,
                                        infinite: true,
                                        dots: true
                                    }
                                },
                                {
                                    breakpoint: 620,
                                    settings: {
                                        slidesToShow: 2,
                                        slidesToScroll: 2
                                    }
                                },
                                {
                                    breakpoint: 480,
                                    settings: {
                                        slidesToShow: 1,
                                        slidesToScroll: 1
                                    }
                                }
                            ]
                        });
                    }else{
                        $(this).find("ul").slick({
                            dots: false,
                            arrows: true,
                            slidesToShow: 4,
                            slidesToScroll: 4,
                            responsive: [
                                {
                                    breakpoint: 768,
                                    settings: {
                                        slidesToShow: 3,
                                        slidesToScroll: 3,
                                        infinite: true,
                                        dots: true
                                    }
                                },
                                {
                                    breakpoint: 620,
                                    settings: {
                                        slidesToShow: 2,
                                        slidesToScroll: 2
                                    }
                                },
                                {
                                    breakpoint: 480,
                                    settings: {
                                        slidesToShow: 1,
                                        slidesToScroll: 1
                                    }
                                }
                            ]
                        });
                    }
                });
            }
        };

        /*this.toolbar_admin_height = function () {
         $(window).on('resize', function () {
         console.log($('#toolbar-bar').height() + $('#toolbar-bar').find('#toolbar-item-administration-tray .toolbar-lining').height());
         });
         };*/

        this.node_view = function () {
            var $page_node_type_front_page_main_slide = $('.page-node-type-front-page-main-slide');
            var $items = $page_node_type_front_page_main_slide.find('#block-qarea-content article > div > div');
            $items.each(function (i, el) {
                $(this).addClass("ind_" + (i + 1));
            });

        };

        this.change_val_form_input = function () {
            $(".node-lottery-bid-form #edit-actions .js-form-submit").val('');
            setTimeout(function () {
                if($("body.lang_fa").length){
                    $(".node-lottery-bid-form #edit-actions .js-form-submit").val('شرکت کردن');
                }else{
                    $(".node-lottery-bid-form #edit-actions .js-form-submit").val('Participate');
                }
            }, 0);
        };

        this.timer = function () {
            // create html part for timer
            var $time_block_parts = '<div class="wrap_timer"><div class="time_days"></div><div class="time_hours"></div><div class="time_minutes"></div><div class="time_seconds"></div></div>';
            // find html blocks for timer
            var $expires_block = $(".middle_links_wrapper .views-row");
            function getTime(endTime) {
                var total = Date.parse(endTime) - Date.parse(new Date()),
                    seconds = Math.floor((total / 1000) % 60),
                    minutes = Math.floor((total / 1000 / 60) % 60),
                    hours = Math.floor((total / (1000 * 60 * 60)) % 24),
                    days = Math.floor(total / (1000 * 60 * 60 * 24));
                if(days < 10){
                    days = '0' + days;
                }
                if(hours < 10){
                    hours = '0' + hours;
                }
                if(minutes < 10){
                    minutes = '0' + minutes;
                }
                if(seconds < 10){
                    seconds = '0' + seconds;
                }

                if (total <= 0) {
                    return {
                        message: Drupal.t("event has done")
                    }
                }else{
                    return {
                        'total': total,
                        'days': days,
                        'hours': hours,
                        'minutes': minutes,
                        'seconds': seconds
                    };
                }
            }
            function initTimer(endTime, $this){
                var $wrap_timer = $this.find(".event_date .wrap_timer"),
                    $wrap_timer_days = $wrap_timer.find(".time_days"),
                    $wrap_timer_hours = $wrap_timer.find(".time_hours"),
                    $wrap_timer_minutes = $wrap_timer.find(".time_minutes"),
                    $wrap_timer_seconds = $wrap_timer.find(".time_seconds");
                function updateClock(){
                    var total = getTime(endTime);
                    if(total.total > 0){
                        $wrap_timer_days.html(total.days);
                        $wrap_timer_hours.html(total.hours);
                        $wrap_timer_minutes.html(total.minutes);
                        $wrap_timer_seconds.html(total.seconds);
                    }else{
                        $wrap_timer.html('<div class="event_done">' + total.message + '</div>');
                        clearInterval(timeInterval);
                    }
                }updateClock();
                var timeInterval = setInterval(updateClock,1000);
            }
            $expires_block.each(function (i, el) {
                var $this = $(this);
                $this.addClass("index_timer_" + i);
                // get data time from drupal
                var $event_date = $(this).find(".views-field .field-content a .event_date"),
                    $event_date_time = $event_date.find("time").html(),
                    change_str_time = $event_date_time.split(' ');
                var change_str_time_join = change_str_time[0]+'-'+change_str_time[1]+'-'+change_str_time[2]+'T'+change_str_time[3];
             // console.log($event_date_time, $event_date, change_str_time_join);
                var dead_time = new Date(change_str_time_join);
                $event_date.append($time_block_parts);
                //console.log(dead_time);
                initTimer(dead_time, $this);
            });
        };

        this.tip_left_right_wrap = function () {
            $(".tip_status").closest("div").addClass("div_wrap_tip");
            $(".tip_left, .tip_right").wrapAll("<div class='tip_left_right_wrap'></div>");
        };

        /*this.wrap_for_safebet = function () {
            $(".views-element-container.block-views-blocksafebet-banners-block-1 .views-row").each(function (i, elem) {
                $(this).find("> div").wrap(function () {
                    if(true){}
                });
                if(i == 1 && i == 2){
                    $(this).find("div").wrap("<div class='title_status_wrap'></div>");
                }
            });
        };*/


        this.user_title_hide = function () {
            $("body #block-qarea-content .agent-percents").closest("article").addClass("article_agent_percents_wrap");
            $(".article_agent_percents_wrap").closest("#content").find("h1.js-quickedit-page-title").hide();
            // js-quickedit-page-title
        };

        this.field__name_field_to_user = function () {
              //$("form.node-bookmakers-form .field--name-field-to-user input[type=text]").attr("pattern", '.{5,10}');
                $("form.node-bookmakers-form input[type=text]").attr("maxlength", '100');
        };

        this.add_class_terms = function () {
            $("form#entity-legal-document-acceptance-form").closest("#block-qarea-content").addClass("terms_and_conditions_wrap");
        };

        this.tip_content_false_link = function () {
            $(".tip_content").each(function () {
                $(this).find(".tip_main article h2 a").removeAttr("href");
                $(this).find(".tip_main article h2 a").on("click", function () {
                    $(this).removeAttr("href");
                    return false;
                });
            });
            $("body.page-node-type-tip #block-qarea-content article div .tip_content .tip_main").each(function () {
                $(this).append("<div class='vs_vs'>vs</div>");
            });
        };

        this.form_user_condition = function () {
            if($("body.path-user").find(".messages--error").length && $("body.path-user").find(".user-register-form").length){
                $(".form-item-legal-terms-and-conditions").css({
                    "bottom": 24 + '%'
                });
            }else{
                $(".form-item-legal-terms-and-conditions").removeAttr("style");
            }
        };

        this.init = function () {
            //_that.hover_reg_item();
            _that.user_tickets_tab_wrap_moving();
            _that.scroll_bar();
            _that.hover_sub_menu();
            _that.main_slide_show_slider();
            _that.bookmaker_slider();
            //_that.toolbar_admin_height();
            _that.tip_left_right_wrap();
            _that.main_table_wrapper();
            _that.login_links();
            _that.language_switcher();
            _that.timer();
            _that.node_view();
            _that.change_val_form_input();
            _that.user_title_hide();
            _that.field__name_field_to_user();
            _that.user_register_form();
            _that.create_tip_wrapper();
            _that.tip_content_false_link();
            _that.add_class_terms();
            _that.form_user_condition();
            //_that.wrap_for_safebet();
            //_that.custom_radio();
            _that.slide_menu();
            _that.slider_head_matches();
        };

    };

    var ns = new NS();

    $(document).ready(function () {
        ns.init();
        if($(window).width() >= 768){
            $("body.path-frontpage #main").css({
                "top": - $("#header").outerHeight()
            });
            $("body.path-frontpage #footer").css({
                "margin-top": - $("#header").outerHeight()
            });
        }else{
            $("body.path-frontpage #main").css({
                "top": 0
            });
            $("body.path-frontpage #footer").css({
                "margin-top": 0
            });
        }
    });

    $(window).on("resize", function () {
        if($(window).width() >= 768){
            $("body.path-frontpage #main").css({
                "top": - $("#header").outerHeight()
            });
            $("body.path-frontpage #footer").css({
                "margin-top": - $("#header").outerHeight()
            });
        }else{
            $("body.path-frontpage #main").css({
                "top": 0
            });
            $("body.path-frontpage #footer").css({
                "margin-top": 0
            });
        }
    });

})(jQuery);
jQuery(document).ready(function(){
  jQuery('.main-slideshow .item-list > ul').slick({
    arrows: true,
    dots: true,
    autoplay: true,
    autoplaySpeed: 10000
  });
});